<template>
  <addField
    path="serve.php?f=configuration&f2=subscriber"
    :additionalAttributes="additionalAttributes"
    :showDialog="openDialog"
    @dialog-closed="openDialog=false"
  >
    <v-btn text>{{ info.name }}</v-btn>
  </addField>
</template>
<script>
import addField from "@/commonComponents/addField.vue";
export default {
  components: { addField },
  data() {
    return { openDialog: false };
  },
  props: ["info", "actionName", "currentTable"],
  computed: {
    additionalAttributes() {
      return { function: "getInsertedFieldsImport", updateTable: "true" };
    },
  },
};

// function callMultiSubscriberImport(caller){
//   let returnedDialog = AddField('' ,'' ,'serve.php?f=configuration&f2=subscriber',{ function:'getInsertedFieldsImport','updateTable':'true' },caller,{ width:800 },'Import');
// }
</script>